<template>
	<div class="page_container">
		<el-breadcrumb separator="/" class="page_content_top page_content_lr">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/news' }"> 新闻资讯</el-breadcrumb-item>
			<el-breadcrumb-item>{{ detail.title }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="page_content_detail page_content_lr">
			<h1 class="page_content_detail_title">{{ detail.title }}</h1>
			<div class="page_content_detail_info">
				<div class="page_content_detail_info_date">{{detail.create}}</div>
				<div class="page_content_detail_info_view">浏览量：{{detail.view}}</div>
			</div>
			<div class="page_content_detail_bg">
				<p class="page_content_detail_item" v-for="it of descs">{{it}}</p>
				<div class="page_content_detail_imgs">
					<img class="page_content_detail_img" v-for="img of imgs" :src="imgUrl(img)" alt="" />
				</div>
			</div>
			<div class="page_content_detail_bottom_bg">
				<a class="page_content_detail_bottom_last" @click="handleLast(last)"><-上一篇：{{last ? last.title : "无"}}</a>
				<a class="page_content_detail_bottom_next" @click="handleNext(next)">->下一篇：{{next ? next.title : "无"}}</a>
			</div>
		</div>
	</div>
</template>

<script>
import { news_info } from '@/utils/info.js';

export default {
	name: 'Home',
	props: {},
	watch: {},
	data() {
		return {
			id: this.$route.params.id, // 从路由参数中获取 id
		};
	},
	watch: {
		'$route.params.id': function(newId) {
			this.id = newId; // 当 id 改变时更新组件中的 id
		},
	},
	computed: {
		detail(){
			return news_info?.find((it) => it.id == this.id);
		},
		descs(){
			return this.detail.descs;
		},
		imgs(){
			return this.detail.images;
		},
		imgUrl: () => (img) => {
			return require(`@/assets/images/${img}`);
		},
		next(){
			const index = news_info?.findIndex((it) => it.id == this.id);
			if(index < news_info?.length - 1){
				return news_info?.[index + 1];
			}else{
				return null;
			}
		},
		last(){
			const index = news_info?.findIndex((it) => it.id == this.id);
			if(index > 0){
				return news_info?.[index - 1];
			}else{
				return null;
			}
		},
	},
	mounted(){
	},
	created(){
		
	},
	methods: {
		handleNext(next){
			if(next){
				this.$router.push(`/detail/${next.id}`);
			}
		},
		handleLast(last){
			if(last){
				this.$router.push(`/detail/${last.id}`);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.page_container{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 80px auto 0;
	max-width: 1200px;
	.page_content_top{
		padding: 15px 0 30px;
	}
	.page_content_detail{
		display: flex;
		flex-direction: column;
		align-items: center;
		.page_content_detail_title{
			padding: 0;
			margin: 0;
			width: 100%;
			height: auto;
			font-family: 'Microsoft YaHei';
			color: #404040;
			font-size: 24px;
			font-weight: normal;
			font-style: normal;
			text-decoration: none;
			text-align: center;
			line-height: 34px;
			word-wrap: break-word;
			word-break: normal;
		}
		.page_content_detail_info{
			margin: 20px 0;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-family: 'Microsoft YaHei';
			color: #999999;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			text-decoration: none;
			text-align: left;
			word-wrap: break-word;
			word-break: normal;
			.page_content_detail_info_date{
				width: 46%;
				text-align: right;
			}
			.page_content_detail_info_view{
				width: 46%;
				text-align: left;
			}
		}
		.page_content_detail_bg{
			margin: 20px 0 30px;
			font-family: 'Microsoft YaHei';
			color: #404040;
			width: 100%;
			font-size: 20px;
			font-weight: normal;
			font-style: normal;
			text-decoration: none;
			text-align: left;
			text-indent: 2em; /* 设置首行缩进为两个字符的宽度 */
			line-height: 40px;
			word-wrap: break-word;
			word-break: normal;
			.page_content_detail_item{}
			.page_content_detail_imgs{
				margin-top: 10px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.page_content_detail_img{
					max-width: calc(100% - 80px);
				}
			}
		}
		.page_content_detail_bottom_bg{
			margin: 40px 40px 80px;
			width: calc(100% - 80px);
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-family: 'Microsoft YaHei';
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			line-height: 24px;
			word-wrap: break-word;
			word-break: normal;
			.page_content_detail_bottom_last{
				color: #666;
			}
			.page_content_detail_bottom_next{
				color: #666;
			}
		}
	}
	.page_content_lr{
		margin: 0 80px;
	}
}
@media (max-width: 940px) {
	.page_container{
		.page_content_top{
			line-height: 24px;
		}
		.page_content_detail{
			.page_content_detail_title{
				font-size: 20px;
			}
			.page_content_detail_bg{
				font-size: 16px;
				.page_content_detail_imgs{
					.page_content_detail_img{
						max-width: 100%;
						margin-bottom: 12px;
					}
				}
			}
			.page_content_detail_bottom_bg{
				margin: 10px 10px 20px;
				width: calc(100% - 20px);
				flex-direction: column;
				align-items: flex-start;
			}
		}
		.page_content_lr{
			margin: 0 20px;
		}
	}
}
</style>
